<template>
  <div class="wrap upflie">
    <van-form input-align="right" error-message-align="right" label-width='4.5em'>
      <!-- <img src="@/assets/img/rentPay.png" alt="logo图片" width="100%"> -->
      <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" :value="内容" /> -->
      <van-field readonly label="姓名" :value="list.customername" />
      <van-field autosize readonly label="合同号" :value="list.con_no" />
      <van-field readonly label="车架号" :value="list.vin" />
      <van-field readonly label="转账时间" :value="list.pay_day" />
      <van-field readonly rows="1" autosize type="textarea" label="转账户名" :value="list.account_name" />
      <van-field readonly label="转账金额" :value="list.amount" />
      <van-cell title="附件上传" is-link :to="{ path: '/upFileDetail', query: { contractNum: list.con_no,wxid: list.wxid}}" value="已上传" />
      <van-field readonly label="申请时间" :value="list.apply_time" />
    </van-form>
    <div v-if="list.approval_status=='2'">
      <div offset="1" style="color:#1A1A1A;font-size:20px;margin:15px;">拒绝原因：</div>
      <div class="tips">
        <p>{{list.back_msg}} </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
  data() {
    return {
      list:this.$store.state.dataDetailList,
      imgtype:'com_account_base',//附件展示标志
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'corpor3');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'corpor3') {
    //     that.$router.push({ path: '/application?flag=1' });
    //   }
    // }, false);
  },
  created(){
  },
  methods: {
  },
};
</script>
<style scoped>
.wrap{
  padding: 10px 0px; 
  background-color: #fff; 
  height: 100%;
  }
.tips{
  color:#808080;
  font-size:13px;
  background-color: #F9F9F9;
  margin: 10px;
  min-height:50px;
  padding: 15px;
  /* margin-left: 12px; */
}
</style>
<style>
textarea{
  overflow:hidden;
}
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
</style>